<template>
  <div class="personalCenterContainer">
    <div class="titles">
      登陆密码修改
    </div>
    <div style="background: #F2F3F8;height: 20px;width:100%">

    </div>
    <div class="setPassword">
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input :type="oldPwdText" v-model="form.oldPassword">
              <img slot="suffix" :src="closeEye" v-if="PassOldtype" @click="showPwd" />
              <img slot="suffix"  :src="openEye" v-else  @click="hidePwd" />
          </el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="password">
          <el-input :type="newPwdText" v-model="form.password">
              <img slot="suffix" :src="closeEye" v-if="passNewType" @click="showPwd1" />
              <img slot="suffix"  :src="openEye" v-else  @click="hidePwd1" />
          </el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="passwordSecond">
          <el-input :type="newAginPwdText" v-model="form.passwordSecond">
            <img slot="suffix" :src="closeEye" v-if="newPwdType" @click="showPwd2" />
            <img slot="suffix"  :src="openEye" v-else  @click="hidePwd2" />
          </el-input>
        </el-form-item>
        <div class="submitSetPassword">
          <el-button type="primary" @click="submitSetPassword()">提交</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import md5 from "js-md5";
export default {
  data() {
    var validatePassword = (rule, value, callback) => {
      if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      loginAccount: null,
       oldPwdText:'password',
      newPwdText:'password',
      newAginPwdText:'password',
      PassOldtype:true,
      passNewType:true,
      newPwdType:true,
      closeEye:require('../../../assets/images/closeeye.png'),
      openEye:require('../../../assets/images/openeye.png'),
      form: {
        oldPassword: null,
        password: null,
        passwordSecond: null
      },
      rules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "change" }
        ],
        password: [
          { required: true, message: "请输入密码", trigger: "change" },
          {
            // pattern:  /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[])+$)([^(0-9a-zA-Z)]|[]|[a-z]|[A-Z]|[0-9]|[\()]){8,20}$/,
            pattern:  /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8}$/,
            message: "请输入8位字母，数字"
          }
        ],
        passwordSecond: [
          { required: true, message: "请再次输入密码", trigger: "change" },
          {
            // pattern: /^(?![0-9]+$)(?![a-z]+$)(?![A-Z]+$)(?!([^(0-9a-zA-Z)]|[])+$)([^(0-9a-zA-Z)]|[]|[a-z]|[A-Z]|[0-9]|[\()]){8,20}$/,
            pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8}$/,
            message: "请输入8位字母，数字"
          },
          { validator: validatePassword, trigger: "change" }
        ]
        // (?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{8,20}$
      },
      // dialogVisible: false
    };
  },
  computed: {
    ...mapGetters(["sessionInfoGetter"])
  },
  methods: {
    // showDialog() {
    //   this.dialogVisible = true;
    // },
    showPwd(){
      this.PassOldtype = false
      this.oldPwdText = 'text'
    },
    hidePwd(){
      this.PassOldtype= true
      this.oldPwdText = 'password'
    },
     showPwd1(){
      this.passNewType = false
      this.newPwdText = 'text'
    },
    hidePwd1(){
      this.passNewType= true
      this.newPwdText = 'password'
    },
     showPwd2(){
      this.newPwdType = false
      this.newAginPwdText = 'text'
    },
    hidePwd2(){
      this.newPwdType= true
      this.newAginPwdText = 'password'
    },
    submitSetPassword() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          protocolFwd.param_updatePwdByOld.param.loginAccount = this.sessionInfoGetter.tel;
          protocolFwd.param_updatePwdByOld.param.oldPassword = md5('47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=' + this.form.oldPassword);
          protocolFwd.param_updatePwdByOld.param.password = md5('47DEQpj8HBSa+/TImW+5JCeuQeRkm5NMpJWZG3hSuFU=' + this.form.password);
          http.postFront(protocolFwd.param_updatePwdByOld).then(response => {
            const { code, message, value } = response.data;
            if (code == 0) {
              this.$refs["form"].resetFields();
              // this.dialogVisible = false;
              this.$EL_MESSAGE(message);
              var obj = {}
              obj.name = ''
              this.$store.commit('setHeaderText', obj)
              this.$router.push('/login')
            } else {
              this.$EL_MESSAGE(message);
            }
          });
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/ .el-form-item__label {
  text-align: left;
}
.accountSafeTitle{
  font-size: 16px;
  padding:15px 0 5px 15px;
  border-bottom: 1px solid #eeeeee;
}
.setPassword{
  width: 100%;
  margin-top: 20px;
  padding: 15px 330px;
  box-sizing: border-box;
}
.el-input{
  // width: 60%!important;
}
.personalCenterContainer {
  background-color: #fff;
  height: 100%;
  width: 1200px;
  margin: 0 auto;
  line-height: 32px;
  margin-top: 20px;
}
.personalCenterContainer .personalCenterTitle {
  font-size: 16px;
  border-bottom: 1px solid #eeeeee;
}
.personalCenterContainer .personalCenterTitle span {
  display: inline-block;
  padding: 0 15px;
  border-bottom: 2px solid $commonThemeColor;
}
.submitSetPassword {
  text-align: center;
  // margin-left: 320px;
}
  .titles {
      padding: 15px;
      font-size: 18px;
  }
</style>

